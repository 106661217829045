import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import Cinema from '../screens/Cinema'
import Home from '../screens/Home'
import About from '../screens/About'
import TournamentRouter from './TournamentRouter'
import Tournaments from '../screens/Tournaments'

export default function RootRouter() {
	return (
		<Switch>
			{/* <Route path='/watch'><Cinema /></Route> */}

			{/* <Route exact path='/t/:tournament_id/matches'><TournamentMatches /></Route>
			<Route exact path='/t/:tournament_id/:competition_id/:match_number'><MatchProfile /></Route>
			<Route exact path='/t/:tournament_id/:competition_id'><CompetitionProfile /></Route>
			<Route exact path='/t/:id'><TournamentProfile /></Route>
			<Route exact path='/t'><Tournaments /></Route> */}

			<Route exact path='/t'><Tournaments /></Route>
			<Route path='/t/:tournament_id'><TournamentRouter /></Route>

			{/* <Route exact path='/o/:id'>TODO</Route>
			<Route exact path='/o'>TODO</Route> */}

			{/* <Route exact path='/p/:id'>TODO</Route>
			<Route exact path='/p'>TODO</Route> */}

			<Route exact path='/about'><About /></Route>
			<Route exact path='/'><Home /></Route>

			{/* TODO: 404 page - report to logging? */}
			<Route path='/'>404</Route>
		</Switch>
	)
}
