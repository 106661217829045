
const compsToOrderIndex: { [key: string]: number } = {
	'ms': 1,
	'ws': 2,
	'md': 3,
	'wd': 4,
	'xd': 5,
	'unknown': 99
}

export const sortCompetitions = (comp1: { slug?: string } | null, comp2: { slug?: string } | null): number => {
	if (!comp1 || !comp2) return 0
	const comp1order = compsToOrderIndex[comp1.slug || 'unknown'] || compsToOrderIndex['unknown']
	const comp2order = compsToOrderIndex[comp2.slug || 'unknown'] || compsToOrderIndex['unknown']
	return comp1order - comp2order
}

const round_names = [
	{ short: '?', long: '?' },
	{ short: 'F', long: 'Final' },
	{ short: 'SF', long: 'Semi-Final' },
	{ short: 'QF', long: 'Quarter-Final' },
	{ short: 'R8', long: 'Round of 8' },
	{ short: 'R16', long: 'Round of 16' },
	{ short: 'R32', long: 'Round of 32' },
	{ short: 'R64', long: 'Round of 64' },
]

export const shortRoundName  = (round_id: number): string => round_names[round_id].short
export const longRoundName = (round_id: number): string => round_names[round_id].long
