import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Route, Switch, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import TournamentProfile from '../screens/TournamentProfile'
import { GetTournamentQuery, GetTournamentQueryVariables, Tournament } from '../API'
import { APP } from '../lib/constants'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { LoadingMessage } from '../components/LoadingMessage'
import TournamentMatches from '../screens/TournamentMatches'
import CompetitionRouter from './CompetitionRouter'

const getTournament = /* GraphQL */ `
  query GetTournament($id: ID!) {
    getTournament(id: $id) {
      id
      name
      start_date
      end_date
      location
      location_country_code
      logo
      theme
      match_count
      player_count
      organisation_id
      organisation {
        id
        name
      }
      competitions {
        items {
          id
          slug
          name
          tournament_id
          max_round_id
          players_per_team
        }
        nextToken
      }
    }
  }
`;

export default function TournamentRouter() {
	const { tournament_id } = useParams<{ tournament_id: string }>()

	const q = useQuery<GetTournamentQuery, GetTournamentQueryVariables>(gql(getTournament), {
		variables: { id: tournament_id },
	})
	const tournament = q.data?.getTournament as Tournament

	return (
		<>
			{tournament?.name && <Helmet><title>{tournament.name} | {APP.htmlName}</title></Helmet>}

			<Header />

			{q.loading ?
				<LoadingMessage message='Loading Tournament...' /> :
				(!tournament ?
					// TODO: Nicer error page
					<p>Unknown tournament!</p> :
					(
						<Switch>
							<Route exact path='/t/:tournament_id/matches'><TournamentMatches tournament={tournament} /></Route>
							<Route path='/t/:tournament_id/:competition_id'><CompetitionRouter tournament={tournament} /></Route>
							<Route exact path='/t/:tournament_id'><TournamentProfile tournament={tournament} /></Route>
							{/* TODO: 404 page - report to logging? */}
							<Route path='/'><p>404</p></Route>
						</Switch>
					)
				)
			}

			<Footer />
		</>
	)

}
