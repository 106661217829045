import { useQuery, OperationVariables, DocumentNode, TypedDocumentNode, QueryHookOptions, QueryResult } from '@apollo/client'
import { useEffect } from 'react'

const usePollingQuery = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables> => {
	const pollInterval = options?.pollInterval
	if (options?.pollInterval) options.pollInterval = undefined

	const q = useQuery<TData, TVariables>(query, options)

	useEffect(() => {
		if (pollInterval) {
			q.startPolling(pollInterval)
			return q.stopPolling
		}
	}, [pollInterval])

	return q
}

export default usePollingQuery
