import React from 'react'
import { Helmet } from 'react-helmet'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { isFuture, parseISO } from 'date-fns'

import { gql } from '@apollo/client'
import { getTournamentsByOrganisation } from '../graphql/queries'
import { GetTournamentsByOrganisationQuery, GetTournamentsByOrganisationQueryVariables, ModelSortDirection, Tournament } from '../API'

import usePollingQuery from '../hooks/usePollingQuery'
import { APP } from '../lib/constants'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { TournamentList } from '../components/TournamentList'
import { LoadingMessage } from '../components/LoadingMessage'

export default function Home() {
	const q = usePollingQuery<GetTournamentsByOrganisationQuery, GetTournamentsByOrganisationQueryVariables>(gql(getTournamentsByOrganisation), {
		variables: {
			organisation_id: 'bwf',
			sortDirection: ModelSortDirection.DESC,
		},
		pollInterval: 60000
	})

	const tournaments = q.data?.getTournamentsByOrganisation?.items as Tournament[]

	// q.loading
	// q.fetchMore
	// q.error

	const upcomingTournaments = []
	const currentTournaments = []
	let pastStartIndex = undefined

	if (tournaments) {
		for (let i = 0; i < tournaments.length; i++) {
			const tournament = tournaments[i]
			if (!tournament.start_date || !tournament.end_date) continue
			const endDate = parseISO(tournament.end_date)
			const endInFuture = isFuture(endDate)

			if (endInFuture) {
				const startDate = parseISO(tournament.start_date)
				const startInFuture = isFuture(startDate)
				if (startInFuture) {
					upcomingTournaments.push(tournament)
				} else {
					currentTournaments.push(tournament)
				}
			} else {
				// endInPast
				pastStartIndex = i
				break
			}
		}
	}

	const recentTournaments = tournaments ? tournaments.slice(pastStartIndex || 0) : []

	return (
		<>
			<Helmet>
				<title>{APP.htmlName}</title>
			</Helmet>

			<Header />

			<Container component='main' maxWidth='lg'>
				{q.loading && <LoadingMessage message='Loading...' />}

				{upcomingTournaments.length > 0 && (
					<>
						<Typography variant="h2">Upcoming Tournaments</Typography>
						<Card className='Spacer15'>
							<CardContent>
								<TournamentList tournaments={upcomingTournaments} />
							</CardContent>
						</Card>
					</>
				)}
				{currentTournaments.length > 0 && (
					<>
						<Typography variant="h2">Current Tournaments</Typography>
						<Card className='Spacer15'>
							<CardContent>
								<TournamentList tournaments={currentTournaments} />
							</CardContent>
						</Card>
					</>
				)}
				{recentTournaments.length > 0 && (
					<>
						<Typography variant="h2">Recent Tournaments</Typography>
						<Card className='Spacer15'>
							<CardContent>
								<TournamentList tournaments={recentTournaments} />
							</CardContent>
						</Card>
					</>
				)}
			</Container>

			<Footer />
		</>
	)
}
