/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTournamentsByOrganisation = /* GraphQL */ `
  query GetTournamentsByOrganisation(
    $organisation_id: ID
    $end_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTournamentsByOrganisation(
      organisation_id: $organisation_id
      end_date: $end_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        start_date
        end_date
        location
        location_country_code
        logo
        theme
        match_count
        player_count
        organisation_id
        createdAt
        updatedAt
        organisation {
          id
          name
          createdAt
          updatedAt
          tournaments {
            nextToken
          }
        }
        competitions {
          items {
            id
            slug
            name
            max_round_id
            players_per_team
            tournament_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCompetitionsByTournament = /* GraphQL */ `
  query GetCompetitionsByTournament(
    $tournament_id: ID
    $slug: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompetitionsByTournament(
      tournament_id: $tournament_id
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        max_round_id
        players_per_team
        tournament_id
        createdAt
        updatedAt
        tournament {
          id
          name
          start_date
          end_date
          location
          location_country_code
          logo
          theme
          match_count
          player_count
          organisation_id
          createdAt
          updatedAt
          organisation {
            id
            name
            createdAt
            updatedAt
          }
          competitions {
            nextToken
          }
        }
        matches {
          items {
            id
            tournament_id
            competition_id
            round_id
            match_number
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMatchesByTournament = /* GraphQL */ `
  query GetMatchesByTournament(
    $tournament_id: ID
    $match_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMatchesByTournament(
      tournament_id: $tournament_id
      match_number: $match_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournament_id
        competition_id
        round_id
        match_number
        team1 {
          team_id
          status
          players {
            player_id
            name
          }
          scores
          winner
          seed
        }
        team2 {
          team_id
          status
          players {
            player_id
            name
          }
          scores
          winner
          seed
        }
        createdAt
        updatedAt
        competition {
          id
          slug
          name
          max_round_id
          players_per_team
          tournament_id
          createdAt
          updatedAt
          tournament {
            id
            name
            start_date
            end_date
            location
            location_country_code
            logo
            theme
            match_count
            player_count
            organisation_id
            createdAt
            updatedAt
          }
          matches {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getMatchesByCompetition = /* GraphQL */ `
  query GetMatchesByCompetition(
    $competition_id: ID
    $match_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMatchesByCompetition(
      competition_id: $competition_id
      match_number: $match_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournament_id
        competition_id
        round_id
        match_number
        team1 {
          team_id
          status
          players {
            player_id
            name
          }
          scores
          winner
          seed
        }
        team2 {
          team_id
          status
          players {
            player_id
            name
          }
          scores
          winner
          seed
        }
        createdAt
        updatedAt
        competition {
          id
          slug
          name
          max_round_id
          players_per_team
          tournament_id
          createdAt
          updatedAt
          tournament {
            id
            name
            start_date
            end_date
            location
            location_country_code
            logo
            theme
            match_count
            player_count
            organisation_id
            createdAt
            updatedAt
          }
          matches {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        tournaments {
          items {
            id
            name
            start_date
            end_date
            location
            location_country_code
            logo
            theme
            match_count
            player_count
            organisation_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      createdAt
      updatedAt
      tournaments {
        items {
          id
          name
          start_date
          end_date
          location
          location_country_code
          logo
          theme
          match_count
          player_count
          organisation_id
          createdAt
          updatedAt
          organisation {
            id
            name
            createdAt
            updatedAt
          }
          competitions {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const getTournament = /* GraphQL */ `
  query GetTournament($id: ID!) {
    getTournament(id: $id) {
      id
      name
      start_date
      end_date
      location
      location_country_code
      logo
      theme
      match_count
      player_count
      organisation_id
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
        tournaments {
          items {
            id
            name
            start_date
            end_date
            location
            location_country_code
            logo
            theme
            match_count
            player_count
            organisation_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      competitions {
        items {
          id
          slug
          name
          max_round_id
          players_per_team
          tournament_id
          createdAt
          updatedAt
          tournament {
            id
            name
            start_date
            end_date
            location
            location_country_code
            logo
            theme
            match_count
            player_count
            organisation_id
            createdAt
            updatedAt
          }
          matches {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      slug
      name
      max_round_id
      players_per_team
      tournament_id
      createdAt
      updatedAt
      tournament {
        id
        name
        start_date
        end_date
        location
        location_country_code
        logo
        theme
        match_count
        player_count
        organisation_id
        createdAt
        updatedAt
        organisation {
          id
          name
          createdAt
          updatedAt
          tournaments {
            nextToken
          }
        }
        competitions {
          items {
            id
            slug
            name
            max_round_id
            players_per_team
            tournament_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      matches {
        items {
          id
          tournament_id
          competition_id
          round_id
          match_number
          team1 {
            team_id
            status
            scores
            winner
            seed
          }
          team2 {
            team_id
            status
            scores
            winner
            seed
          }
          createdAt
          updatedAt
          competition {
            id
            slug
            name
            max_round_id
            players_per_team
            tournament_id
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      tournament_id
      competition_id
      round_id
      match_number
      team1 {
        team_id
        status
        players {
          player_id
          name
          affiliations {
            country
            sponsor
          }
        }
        scores
        winner
        seed
      }
      team2 {
        team_id
        status
        players {
          player_id
          name
          affiliations {
            country
            sponsor
          }
        }
        scores
        winner
        seed
      }
      createdAt
      updatedAt
      competition {
        id
        slug
        name
        max_round_id
        players_per_team
        tournament_id
        createdAt
        updatedAt
        tournament {
          id
          name
          start_date
          end_date
          location
          location_country_code
          logo
          theme
          match_count
          player_count
          organisation_id
          createdAt
          updatedAt
          organisation {
            id
            name
            createdAt
            updatedAt
          }
          competitions {
            nextToken
          }
        }
        matches {
          items {
            id
            tournament_id
            competition_id
            round_id
            match_number
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
