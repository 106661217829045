import React from "react";
import { Card, CardContent, Container, Grid, Link, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Twemoji from 'react-twemoji'

import { APP, COMPANY } from "../lib/constants";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export default function About () {
	return (
		<>
			<Helmet>
				<title>About Us | {APP.htmlName}</title>
			</Helmet>

			<Header />

			<Container component='header' maxWidth='lg'>
				<Typography variant="h2">About Us</Typography>
			</Container>

			<Container component='main' maxWidth='lg'>
				<Grid container spacing={2} direction="row">
					<Grid item sm xs={12}>
						<Card className='Spacer15'>
							<CardContent>
								<p>
									At Badminton&sup3; we <Twemoji tag="span" options={{ folder: 'svg', ext: '.svg', attributes: () => ({ title: 'love' }) }}>❤️</Twemoji> <Twemoji tag="span" options={{ folder: 'svg', ext: '.svg', attributes: () => ({ title: 'badminton!' }) }}>🏸</Twemoji> !
									We simply can't get enough of it!
									In addition to playing, as coaches / volunteers / club officers / committee members etc, we also do a lot in our local areas to introduce people to badminton and to provide more opportunities for people to pick up a racket and play.
								</p>
								<p>
									We want to go even further though - helping to spread the news and excitement of our sport internationally!
									We want to make it easier for everyone to discover, access, follow and engage with all the action from the fantastic badminton tournaments going on around the world.
								</p>
								<p>
									Want to help? We would love to hear from you!
								</p>
							</CardContent>
						</Card>
					</Grid>

					<Grid item sm={3} xs={12} className='CardList'>
						<Card className='Spacer15'>
							<CardContent>
								<Typography variant="h6">{COMPANY.fullName}</Typography>
								<div>Registered in England and Wales</div>
								<div>Company Number: {COMPANY.number}</div>
							</CardContent>
						</Card>
						<Card>
							<CardContent>
								<div><strong>Registered Office:</strong></div>
								{COMPANY.addressLines.map((line, index) => <div key={index}>{line}</div>)}
							</CardContent>
						</Card>
					</Grid>

				</Grid>
			</Container>

			<Container component='main' maxWidth='lg'>
				<Grid container spacing={2} direction="row">
					<Grid item md={9} xs={12}>
						<Typography variant="h2">Open Source Attribution</Typography>
						<Card>
							<CardContent>
								<p>The graphics used to display emoji and country flags are provided by <Link href="https://github.com/twitter/twemoji" target="_blank" rel="noopener noreferrer">Twemoji</Link>, created by <Link href="https://twitter.github.io" target="_blank" rel="noopener noreferrer">Twitter Inc</Link> and <Link href="https://github.com/twitter/twemoji/graphs/contributors" target="_blank" rel="noopener noreferrer">contributors</Link>, licensed under <Link href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">CC-BY 4.0</Link>.</p>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>

			<Footer />
		</>
	)
}

// TODO: Contact us form but locked behind a login to reduce spam?
// ICO registration no
// FCA No ...
// VAT No. GB ...

// convenient
// immersive
// entertaining
