import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/ScopedCssBaseline'
import ReactDOM from 'react-dom'

import { APP } from './lib/constants'
import RootRouter from './routing/RootRouter'
import reportWebVitals from './reportWebVitals'

import './index.css'

// AWS Amplify
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

// AWS AppSync
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

// AWS Amplify
Amplify.configure(awsconfig)

// AWS AppSync
const linkConfig: {
	url: string
	region: string
	auth: AuthOptions
} = {
	url: awsconfig.aws_appsync_graphqlEndpoint,
	region: awsconfig.aws_appsync_region,
	auth: {
		// type: awsconfig.aws_appsync_authenticationType,
		type: AUTH_TYPE.API_KEY,
		apiKey: awsconfig.aws_appsync_apiKey
	}
}

const client = new ApolloClient({
	link: ApolloLink.from([
		createAuthLink(linkConfig),
		createSubscriptionHandshakeLink(linkConfig)
	]),
	cache: new InMemoryCache(),
})

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		background: {
			default: 'transparent'
		},
		primary: {
			main: '#fff'
		}
	},
	typography: {
		h2: {
			fontSize: '2.5rem'
		}
	}
})

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline>
						<Helmet>
							<title>{APP.htmlName}</title>
						</Helmet>
						<RootRouter />
					</CssBaseline>
				</ThemeProvider>
			</BrowserRouter>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
