
export const COMPANY = {
	fullName: 'Badminton3 Limited',
	name: 'Badminton3',
	type: 'Limited',
	number: '13237759',
	address: '7 Bell Yard, London, England, WC2A 2JR',
	addressLines: [
		'7 Bell Yard',
		'London',
		'England',
		'WC2A 2JR'
	]
}

export const APP = {
	name: 'badminton³',
	htmlName: 'badminton\u00b3',
}
