import React from 'react'
import { AppBar as MuiAppBar, Button, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'

import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import { Tournament } from 'mdi-material-ui'
import { APP } from '../lib/constants'

import './AppBar.css'

export default function AppBar() {
	return (
		<MuiAppBar position='static' color='transparent' elevation={0}>
			<Toolbar variant='dense' disableGutters>
				<Link to='/' style={{ display: 'flex' }}>
					<img src='/logos/badminton3-title-logo.png' alt={APP.name + ' logo'} style={{ maxHeight: '25px' }} />
				</Link>

				{/* Spacer */}
				<div style={{ flexGrow: 1 }}></div>

				<Button component={Link} to='/' title='Home'>
					<HomeRoundedIcon />
					<span className='AppBarButtonText'>Home</span>
				</Button>

				<Button component={Link} to='/t' title='Tournaments'>
					<Tournament />
					<span className='AppBarButtonText'>Tournaments</span>
				</Button>

				{/* <Button component={Link} to='/p'>
					<PersonRoundedIcon />
					Players
				</Button> */}
			</Toolbar>
		</MuiAppBar>
	)
}
