import React from "react"
import CircularProgress from '@material-ui/core/CircularProgress'

export const LoadingMessage = ({ message }: { message?: string }) => {
	return (
		<div style={{ textAlign: 'center' }}>
			<div><CircularProgress /></div>
			{message && <p>{message}</p>}
		</div>
	)
}
