import React from "react"
import { MatchTeam } from "../API"
import { PlayerRow } from "./PlayerRow"

export const TeamRow = ({ team, opposingTeam, numPlayersPerTeam }: { team?: MatchTeam, opposingTeam?: MatchTeam, numPlayersPerTeam?: number | null }) => {
	if (team?.players) return (<>{team.players.map((player, idx) => player && <PlayerRow key={idx} player={player} />)}</>)

	if (team?.status === 'bye') {
		return <>{Array(numPlayersPerTeam || 2).fill(1).map(() => <div><span className="CountryFlag CountryFlagPlaceholder"></span>BYE</div>)}</>
	}

	// If the match is over then we should know who was in the team, so display a placeholder acknowledging that we have information missing
	if (opposingTeam?.winner !== undefined || (opposingTeam?.scores && opposingTeam?.scores?.length > 0)) {
		return <>{Array(numPlayersPerTeam || 2).fill(1).map(() => <div><span className="CountryFlag CountryFlagPlaceholder"></span>TBC</div>)}</>
	}

	// Otherwise there might not be a winner of the previous round match yet
	return <>{Array(numPlayersPerTeam || 2).fill(1).map(() => <div><span className="CountryFlag CountryFlagPlaceholder"></span>&nbsp;</div>)}</>
}
