import React from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Competition, GetMatchQueryVariables, Match, Tournament } from '../API'
import { Helmet } from 'react-helmet'
import { longRoundName, shortRoundName } from '../lib/Utils'
import { APP } from '../lib/constants'
import { TournamentBreadcrumbs } from '../components/TournamentBreadcrumbs'
import { Card, CardActionArea, CardContent, Container, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { getMatchPositionsForRound, getNextRoundPosition, getPreviousRoundPosition } from 'badminton3-lib'
import { TeamRow } from '../components/TeamRow'
import { LoadingMessage } from '../components/LoadingMessage'

export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      round_id
      match_number
      team1 {
        team_id
        players {
          player_id
          name
          affiliations {
            country
            sponsor
          }
        }
		status
        scores
        winner
        seed
      }
      team2 {
        team_id
        players {
          player_id
          name
          affiliations {
            country
            sponsor
          }
        }
		status
        scores
        winner
        seed
      }
      competition {
        id
        slug
        name
		tournament_id
        tournament {
          id
          name
          start_date
          end_date
		  location_country_code
          organisation {
            id
            name
          }
        }
      }
    }
  }
`

export default function MatchProfile({ tournament, competition }: { tournament: Tournament, competition: Competition }) {
	const { match_number } = useParams<{ match_number: string }>()

	// TODO: Polling to pick up result and new videos etc?
	// TODO: Use a subscription if there are live updates? Need a data source first...
	const q = useQuery<{ getMatch?: Match }, GetMatchQueryVariables>(gql(getMatch), { variables: {
		id: competition.id + '|' + match_number
	}})
	const match = q.data?.getMatch

	const matchName = match?.round_id && match?.round_id <= 3 ? longRoundName(match.round_id) : 'Match'

	const roundMatchIds = match?.round_id ? getMatchPositionsForRound(match.round_id) : []
	const previousRoundMatchId = match?.match_number && roundMatchIds.length > 0 && roundMatchIds[0] < match.match_number ? match.match_number - 1 : undefined
	const nextRoundMatchId = match?.match_number && roundMatchIds.length > 0 && roundMatchIds[roundMatchIds.length - 1] > match.match_number ? match.match_number + 1 : undefined

	const roundMatchNumber = match?.round_id && match?.match_number && roundMatchIds.length > 0 ?
		match.match_number - roundMatchIds[0] + 1 :
		undefined

	// TODO: Allow competitions to have more/less than 5 rounds!
	const previousMatch1Id = match?.round_id && match.round_id < 5 ? getPreviousRoundPosition({ match_position: match.match_number!, team_number: 1 }) : undefined
	const previousMatch2Id = match?.round_id && match.round_id < 5 ? getPreviousRoundPosition({ match_position: match.match_number!, team_number: 2 }) : undefined
	const nextMatchId = match?.match_number ? getNextRoundPosition(match.match_number)?.match_position : undefined

	return (
		<>
			<Helmet>
				<title>
					{match?.team1?.players ? match.team1.players.map(player => player!.name).join(' & ') : '???'} vs.
					{' '}{match?.team2?.players ? match.team2.players.map(player => player!.name).join(' & ') : '???'} |
					{' '}{match?.round_id ? longRoundName(match.round_id) : '???'} |
					{' '}{competition.name} |
					{' '}{tournament.name} |
					{' '}{APP.htmlName}
				</title>
			</Helmet>

			<Container component='header' maxWidth='lg'>
				<TournamentBreadcrumbs tournament={tournament} competition={competition} match={match} />
				<Typography variant='h2'>{matchName} #{roundMatchNumber}</Typography>
			</Container>

			{q.loading ?
				<LoadingMessage message='Loading Match...' /> :
				(!match ?
					<p>Match not found!</p> :
					(
						<Container component='main' maxWidth='lg'>
							<div style={{
								display: 'grid',
								gridTemplateColumns: 'auto 1fr auto 1fr auto',
								gridTemplateRows: 'auto auto auto',
								rowGap: '2rem',
							}}>
								<div style={{ gridRowStart: 1, gridColumnStart: 3 }}>
									{previousRoundMatchId && (
										<CardActionArea component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + previousRoundMatchId}>
											<Card style={{ textAlign: 'center' }}>
												<CardContent>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<ArrowUpwardIcon />
														<div style={{ margin: '0 0.5em' }}>{roundMatchNumber && matchName + ' #' + (roundMatchNumber - 1)}</div>
														<ArrowUpwardIcon />
													</div>
												</CardContent>
											</Card>
										</CardActionArea>
									)}
								</div>
								<div style={{ gridRowStart: 2, gridColumnStart: 1, display: 'flex', flexDirection: 'column' }}>
									<Grid container direction='column' style={{ flex: 1 }}>
										<Grid item md style={{ display: 'flex', flexDirection: 'column' }}>
											{previousMatch1Id && (
												<CardActionArea component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + previousMatch1Id} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
													<Card style={{ textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
														<CardContent>
															<ArrowBackIcon />
															<div>{match.round_id && shortRoundName(match.round_id + 1)}</div>
														</CardContent>
													</Card>
												</CardActionArea>
											)}
										</Grid>

										{/* Spacer */}
										{previousMatch1Id && previousMatch2Id && <div style={{ height: '1rem' }}></div>}

										<Grid item md style={{ display: 'flex', flexDirection: 'column' }}>
											{previousMatch2Id && (
												<CardActionArea component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + previousMatch2Id} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
													<Card style={{ textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
														<CardContent>
															<ArrowBackIcon />
															<div>{match.round_id && shortRoundName(match.round_id + 1)}</div>
														</CardContent>
													</Card>
												</CardActionArea>
											)}
										</Grid>
									</Grid>
								</div>
								<div style={{ gridRowStart: 2, gridColumnStart: 3, display: 'flex', flexDirection: 'column' }}>
									<Card style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
										<CardContent>
											<TableContainer>
												<Table size="small">
													<TableBody>
														{/* <TableRow component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + match.match_number}> */}
														<TableRow>
															<TableCell className={match.team1?.winner ? 'winner' : ''}><TeamRow team={match.team1} opposingTeam={match.team2} numPlayersPerTeam={competition.players_per_team} /></TableCell>
															{match.team1?.scores?.map((score, gameNumber) => <TableCell align='center' key={gameNumber}>{score}</TableCell>)}
														</TableRow>
														<TableRow>
															<TableCell className={match.team2?.winner ? 'winner' : ''}><TeamRow team={match.team2} opposingTeam={match.team1} numPlayersPerTeam={competition.players_per_team} /></TableCell>
															{match.team2?.scores?.map((score, gameNumber) => <TableCell align='center' key={gameNumber}>{score}</TableCell>)}
														</TableRow>
													</TableBody>
												</Table>
											</TableContainer>
										</CardContent>
									</Card>
								</div>
								<div style={{ gridRowStart: 2, gridColumnStart: 5, display: 'flex', flexDirection: 'column' }}>
									{nextMatchId && (
										<CardActionArea style={{ flex: 1, display: 'flex', flexDirection: 'column' }} component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + nextMatchId}>
											<Card style={{ textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
												<CardContent>
													<ArrowForwardIcon />
													<div>{match.round_id && shortRoundName(match.round_id - 1)}</div>
												</CardContent>
											</Card>
										</CardActionArea>
									)}
								</div>
								<div style={{ gridRowStart: 3, gridColumnStart: 3 }}>
									{nextRoundMatchId && (
										<CardActionArea component={RouterLink} to={'/t/' + match.competition?.tournament_id + '/' + match.competition?.slug + '/' + nextRoundMatchId}>
											<Card style={{ textAlign: 'center' }}>
												<CardContent>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<ArrowDownwardIcon />
														<div style={{ margin: '0 0.5em' }}>{roundMatchNumber && matchName + ' #' + (roundMatchNumber + 1)}</div>
														<ArrowDownwardIcon />
													</div>
												</CardContent>
											</Card>
										</CardActionArea>
									)}
								</div>
							</div>
						</Container>
					)
				)
			}
		</>
	)
}
