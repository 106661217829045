/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrganisationInput = {
  id?: string | null,
  name: string,
};

export type ModelOrganisationConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelOrganisationConditionInput | null > | null,
  or?: Array< ModelOrganisationConditionInput | null > | null,
  not?: ModelOrganisationConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Organisation = {
  __typename: "Organisation",
  id?: string,
  name?: string,
  createdAt?: string,
  updatedAt?: string,
  tournaments?: ModelTournamentConnection,
};

export type ModelTournamentConnection = {
  __typename: "ModelTournamentConnection",
  items?:  Array<Tournament | null > | null,
  nextToken?: string | null,
};

export type Tournament = {
  __typename: "Tournament",
  id?: string,
  name?: string,
  start_date?: string | null,
  end_date?: string | null,
  location?: string | null,
  location_country_code?: string | null,
  logo?: string | null,
  theme?: string | null,
  match_count?: number | null,
  player_count?: number | null,
  organisation_id?: string,
  createdAt?: string,
  updatedAt?: string,
  organisation?: Organisation,
  competitions?: ModelCompetitionConnection,
};

export type ModelCompetitionConnection = {
  __typename: "ModelCompetitionConnection",
  items?:  Array<Competition | null > | null,
  nextToken?: string | null,
};

export type Competition = {
  __typename: "Competition",
  id?: string,
  slug?: string,
  name?: string,
  max_round_id?: number | null,
  players_per_team?: number | null,
  tournament_id?: string,
  createdAt?: string,
  updatedAt?: string,
  tournament?: Tournament,
  matches?: ModelMatchConnection,
};

export type ModelMatchConnection = {
  __typename: "ModelMatchConnection",
  items?:  Array<Match | null > | null,
  nextToken?: string | null,
};

export type Match = {
  __typename: "Match",
  id?: string,
  tournament_id?: string,
  competition_id?: string,
  round_id?: number,
  match_number?: number,
  team1?: MatchTeam,
  team2?: MatchTeam,
  createdAt?: string,
  updatedAt?: string,
  competition?: Competition,
};

export type MatchTeam = {
  __typename: "MatchTeam",
  team_id?: string | null,
  status?: string | null,
  players?:  Array<MatchPlayer | null > | null,
  scores?: Array< string | null > | null,
  winner?: boolean | null,
  seed?: string | null,
};

export type MatchPlayer = {
  __typename: "MatchPlayer",
  player_id?: string | null,
  name?: string,
  affiliations?: Affiliations,
};

export type Affiliations = {
  __typename: "Affiliations",
  country?: string | null,
  sponsor?: string | null,
};

export type UpdateOrganisationInput = {
  id: string,
  name?: string | null,
};

export type DeleteOrganisationInput = {
  id?: string | null,
};

export type CreateTournamentInput = {
  id?: string | null,
  name: string,
  start_date?: string | null,
  end_date?: string | null,
  location?: string | null,
  location_country_code?: string | null,
  logo?: string | null,
  theme?: string | null,
  match_count?: number | null,
  player_count?: number | null,
  organisation_id: string,
};

export type ModelTournamentConditionInput = {
  name?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  location?: ModelStringInput | null,
  location_country_code?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  match_count?: ModelIntInput | null,
  player_count?: ModelIntInput | null,
  organisation_id?: ModelIDInput | null,
  and?: Array< ModelTournamentConditionInput | null > | null,
  or?: Array< ModelTournamentConditionInput | null > | null,
  not?: ModelTournamentConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateTournamentInput = {
  id: string,
  name?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  location?: string | null,
  location_country_code?: string | null,
  logo?: string | null,
  theme?: string | null,
  match_count?: number | null,
  player_count?: number | null,
  organisation_id?: string | null,
};

export type DeleteTournamentInput = {
  id?: string | null,
};

export type CreateCompetitionInput = {
  id?: string | null,
  slug: string,
  name: string,
  max_round_id?: number | null,
  players_per_team?: number | null,
  tournament_id: string,
};

export type ModelCompetitionConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  max_round_id?: ModelIntInput | null,
  players_per_team?: ModelIntInput | null,
  tournament_id?: ModelIDInput | null,
  and?: Array< ModelCompetitionConditionInput | null > | null,
  or?: Array< ModelCompetitionConditionInput | null > | null,
  not?: ModelCompetitionConditionInput | null,
};

export type UpdateCompetitionInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  max_round_id?: number | null,
  players_per_team?: number | null,
  tournament_id?: string | null,
};

export type DeleteCompetitionInput = {
  id?: string | null,
};

export type CreateMatchInput = {
  id?: string | null,
  tournament_id: string,
  competition_id: string,
  round_id: number,
  match_number: number,
  team1?: MatchTeamInput | null,
  team2?: MatchTeamInput | null,
};

export type MatchTeamInput = {
  team_id?: string | null,
  status?: string | null,
  players?: Array< MatchPlayerInput | null > | null,
  scores?: Array< string | null > | null,
  winner?: boolean | null,
  seed?: string | null,
};

export type MatchPlayerInput = {
  player_id?: string | null,
  name: string,
  affiliations?: AffiliationsInput | null,
};

export type AffiliationsInput = {
  country?: string | null,
  sponsor?: string | null,
};

export type ModelMatchConditionInput = {
  tournament_id?: ModelIDInput | null,
  competition_id?: ModelIDInput | null,
  round_id?: ModelIntInput | null,
  match_number?: ModelIntInput | null,
  and?: Array< ModelMatchConditionInput | null > | null,
  or?: Array< ModelMatchConditionInput | null > | null,
  not?: ModelMatchConditionInput | null,
};

export type UpdateMatchInput = {
  id: string,
  tournament_id?: string | null,
  competition_id?: string | null,
  round_id?: number | null,
  match_number?: number | null,
  team1?: MatchTeamInput | null,
  team2?: MatchTeamInput | null,
};

export type DeleteMatchInput = {
  id?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTournamentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  location?: ModelStringInput | null,
  location_country_code?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  match_count?: ModelIntInput | null,
  player_count?: ModelIntInput | null,
  organisation_id?: ModelIDInput | null,
  and?: Array< ModelTournamentFilterInput | null > | null,
  or?: Array< ModelTournamentFilterInput | null > | null,
  not?: ModelTournamentFilterInput | null,
};

export type ModelCompetitionFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  max_round_id?: ModelIntInput | null,
  players_per_team?: ModelIntInput | null,
  tournament_id?: ModelIDInput | null,
  and?: Array< ModelCompetitionFilterInput | null > | null,
  or?: Array< ModelCompetitionFilterInput | null > | null,
  not?: ModelCompetitionFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelMatchFilterInput = {
  id?: ModelIDInput | null,
  tournament_id?: ModelIDInput | null,
  competition_id?: ModelIDInput | null,
  round_id?: ModelIntInput | null,
  match_number?: ModelIntInput | null,
  and?: Array< ModelMatchFilterInput | null > | null,
  or?: Array< ModelMatchFilterInput | null > | null,
  not?: ModelMatchFilterInput | null,
};

export type ModelOrganisationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelOrganisationFilterInput | null > | null,
  or?: Array< ModelOrganisationFilterInput | null > | null,
  not?: ModelOrganisationFilterInput | null,
};

export type ModelOrganisationConnection = {
  __typename: "ModelOrganisationConnection",
  items?:  Array<Organisation | null > | null,
  nextToken?: string | null,
};

export type CreateOrganisationMutationVariables = {
  input?: CreateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type CreateOrganisationMutation = {
  createOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    tournaments?:  {
      __typename: "ModelTournamentConnection",
      items?:  Array< {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrganisationMutationVariables = {
  input?: UpdateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type UpdateOrganisationMutation = {
  updateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    tournaments?:  {
      __typename: "ModelTournamentConnection",
      items?:  Array< {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrganisationMutationVariables = {
  input?: DeleteOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type DeleteOrganisationMutation = {
  deleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    tournaments?:  {
      __typename: "ModelTournamentConnection",
      items?:  Array< {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTournamentMutationVariables = {
  input?: CreateTournamentInput,
  condition?: ModelTournamentConditionInput | null,
};

export type CreateTournamentMutation = {
  createTournament?:  {
    __typename: "Tournament",
    id: string,
    name: string,
    start_date?: string | null,
    end_date?: string | null,
    location?: string | null,
    location_country_code?: string | null,
    logo?: string | null,
    theme?: string | null,
    match_count?: number | null,
    player_count?: number | null,
    organisation_id: string,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      tournaments?:  {
        __typename: "ModelTournamentConnection",
        items?:  Array< {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    competitions?:  {
      __typename: "ModelCompetitionConnection",
      items?:  Array< {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTournamentMutationVariables = {
  input?: UpdateTournamentInput,
  condition?: ModelTournamentConditionInput | null,
};

export type UpdateTournamentMutation = {
  updateTournament?:  {
    __typename: "Tournament",
    id: string,
    name: string,
    start_date?: string | null,
    end_date?: string | null,
    location?: string | null,
    location_country_code?: string | null,
    logo?: string | null,
    theme?: string | null,
    match_count?: number | null,
    player_count?: number | null,
    organisation_id: string,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      tournaments?:  {
        __typename: "ModelTournamentConnection",
        items?:  Array< {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    competitions?:  {
      __typename: "ModelCompetitionConnection",
      items?:  Array< {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTournamentMutationVariables = {
  input?: DeleteTournamentInput,
  condition?: ModelTournamentConditionInput | null,
};

export type DeleteTournamentMutation = {
  deleteTournament?:  {
    __typename: "Tournament",
    id: string,
    name: string,
    start_date?: string | null,
    end_date?: string | null,
    location?: string | null,
    location_country_code?: string | null,
    logo?: string | null,
    theme?: string | null,
    match_count?: number | null,
    player_count?: number | null,
    organisation_id: string,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      tournaments?:  {
        __typename: "ModelTournamentConnection",
        items?:  Array< {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    competitions?:  {
      __typename: "ModelCompetitionConnection",
      items?:  Array< {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCompetitionMutationVariables = {
  input?: CreateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type CreateCompetitionMutation = {
  createCompetition?:  {
    __typename: "Competition",
    id: string,
    slug: string,
    name: string,
    max_round_id?: number | null,
    players_per_team?: number | null,
    tournament_id: string,
    createdAt: string,
    updatedAt: string,
    tournament?:  {
      __typename: "Tournament",
      id: string,
      name: string,
      start_date?: string | null,
      end_date?: string | null,
      location?: string | null,
      location_country_code?: string | null,
      logo?: string | null,
      theme?: string | null,
      match_count?: number | null,
      player_count?: number | null,
      organisation_id: string,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        tournaments?:  {
          __typename: "ModelTournamentConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitions?:  {
        __typename: "ModelCompetitionConnection",
        items?:  Array< {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    matches?:  {
      __typename: "ModelMatchConnection",
      items?:  Array< {
        __typename: "Match",
        id: string,
        tournament_id: string,
        competition_id: string,
        round_id: number,
        match_number: number,
        team1?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        team2?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        competition?:  {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCompetitionMutationVariables = {
  input?: UpdateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type UpdateCompetitionMutation = {
  updateCompetition?:  {
    __typename: "Competition",
    id: string,
    slug: string,
    name: string,
    max_round_id?: number | null,
    players_per_team?: number | null,
    tournament_id: string,
    createdAt: string,
    updatedAt: string,
    tournament?:  {
      __typename: "Tournament",
      id: string,
      name: string,
      start_date?: string | null,
      end_date?: string | null,
      location?: string | null,
      location_country_code?: string | null,
      logo?: string | null,
      theme?: string | null,
      match_count?: number | null,
      player_count?: number | null,
      organisation_id: string,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        tournaments?:  {
          __typename: "ModelTournamentConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitions?:  {
        __typename: "ModelCompetitionConnection",
        items?:  Array< {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    matches?:  {
      __typename: "ModelMatchConnection",
      items?:  Array< {
        __typename: "Match",
        id: string,
        tournament_id: string,
        competition_id: string,
        round_id: number,
        match_number: number,
        team1?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        team2?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        competition?:  {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCompetitionMutationVariables = {
  input?: DeleteCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type DeleteCompetitionMutation = {
  deleteCompetition?:  {
    __typename: "Competition",
    id: string,
    slug: string,
    name: string,
    max_round_id?: number | null,
    players_per_team?: number | null,
    tournament_id: string,
    createdAt: string,
    updatedAt: string,
    tournament?:  {
      __typename: "Tournament",
      id: string,
      name: string,
      start_date?: string | null,
      end_date?: string | null,
      location?: string | null,
      location_country_code?: string | null,
      logo?: string | null,
      theme?: string | null,
      match_count?: number | null,
      player_count?: number | null,
      organisation_id: string,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        tournaments?:  {
          __typename: "ModelTournamentConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitions?:  {
        __typename: "ModelCompetitionConnection",
        items?:  Array< {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    matches?:  {
      __typename: "ModelMatchConnection",
      items?:  Array< {
        __typename: "Match",
        id: string,
        tournament_id: string,
        competition_id: string,
        round_id: number,
        match_number: number,
        team1?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        team2?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        competition?:  {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateMatchMutationVariables = {
  input?: CreateMatchInput,
  condition?: ModelMatchConditionInput | null,
};

export type CreateMatchMutation = {
  createMatch?:  {
    __typename: "Match",
    id: string,
    tournament_id: string,
    competition_id: string,
    round_id: number,
    match_number: number,
    team1?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    team2?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      slug: string,
      name: string,
      max_round_id?: number | null,
      players_per_team?: number | null,
      tournament_id: string,
      createdAt: string,
      updatedAt: string,
      tournament?:  {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      matches?:  {
        __typename: "ModelMatchConnection",
        items?:  Array< {
          __typename: "Match",
          id: string,
          tournament_id: string,
          competition_id: string,
          round_id: number,
          match_number: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateMatchMutationVariables = {
  input?: UpdateMatchInput,
  condition?: ModelMatchConditionInput | null,
};

export type UpdateMatchMutation = {
  updateMatch?:  {
    __typename: "Match",
    id: string,
    tournament_id: string,
    competition_id: string,
    round_id: number,
    match_number: number,
    team1?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    team2?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      slug: string,
      name: string,
      max_round_id?: number | null,
      players_per_team?: number | null,
      tournament_id: string,
      createdAt: string,
      updatedAt: string,
      tournament?:  {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      matches?:  {
        __typename: "ModelMatchConnection",
        items?:  Array< {
          __typename: "Match",
          id: string,
          tournament_id: string,
          competition_id: string,
          round_id: number,
          match_number: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteMatchMutationVariables = {
  input?: DeleteMatchInput,
  condition?: ModelMatchConditionInput | null,
};

export type DeleteMatchMutation = {
  deleteMatch?:  {
    __typename: "Match",
    id: string,
    tournament_id: string,
    competition_id: string,
    round_id: number,
    match_number: number,
    team1?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    team2?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      slug: string,
      name: string,
      max_round_id?: number | null,
      players_per_team?: number | null,
      tournament_id: string,
      createdAt: string,
      updatedAt: string,
      tournament?:  {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      matches?:  {
        __typename: "ModelMatchConnection",
        items?:  Array< {
          __typename: "Match",
          id: string,
          tournament_id: string,
          competition_id: string,
          round_id: number,
          match_number: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetTournamentsByOrganisationQueryVariables = {
  organisation_id?: string | null,
  end_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTournamentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTournamentsByOrganisationQuery = {
  getTournamentsByOrganisation?:  {
    __typename: "ModelTournamentConnection",
    items?:  Array< {
      __typename: "Tournament",
      id: string,
      name: string,
      start_date?: string | null,
      end_date?: string | null,
      location?: string | null,
      location_country_code?: string | null,
      logo?: string | null,
      theme?: string | null,
      match_count?: number | null,
      player_count?: number | null,
      organisation_id: string,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        tournaments?:  {
          __typename: "ModelTournamentConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitions?:  {
        __typename: "ModelCompetitionConnection",
        items?:  Array< {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCompetitionsByTournamentQueryVariables = {
  tournament_id?: string | null,
  slug?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompetitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCompetitionsByTournamentQuery = {
  getCompetitionsByTournament?:  {
    __typename: "ModelCompetitionConnection",
    items?:  Array< {
      __typename: "Competition",
      id: string,
      slug: string,
      name: string,
      max_round_id?: number | null,
      players_per_team?: number | null,
      tournament_id: string,
      createdAt: string,
      updatedAt: string,
      tournament?:  {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      matches?:  {
        __typename: "ModelMatchConnection",
        items?:  Array< {
          __typename: "Match",
          id: string,
          tournament_id: string,
          competition_id: string,
          round_id: number,
          match_number: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMatchesByTournamentQueryVariables = {
  tournament_id?: string | null,
  match_number?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMatchesByTournamentQuery = {
  getMatchesByTournament?:  {
    __typename: "ModelMatchConnection",
    items?:  Array< {
      __typename: "Match",
      id: string,
      tournament_id: string,
      competition_id: string,
      round_id: number,
      match_number: number,
      team1?:  {
        __typename: "MatchTeam",
        team_id?: string | null,
        status?: string | null,
        players?:  Array< {
          __typename: "MatchPlayer",
          player_id?: string | null,
          name: string,
        } | null > | null,
        scores?: Array< string | null > | null,
        winner?: boolean | null,
        seed?: string | null,
      } | null,
      team2?:  {
        __typename: "MatchTeam",
        team_id?: string | null,
        status?: string | null,
        players?:  Array< {
          __typename: "MatchPlayer",
          player_id?: string | null,
          name: string,
        } | null > | null,
        scores?: Array< string | null > | null,
        winner?: boolean | null,
        seed?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      competition?:  {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMatchesByCompetitionQueryVariables = {
  competition_id?: string | null,
  match_number?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMatchesByCompetitionQuery = {
  getMatchesByCompetition?:  {
    __typename: "ModelMatchConnection",
    items?:  Array< {
      __typename: "Match",
      id: string,
      tournament_id: string,
      competition_id: string,
      round_id: number,
      match_number: number,
      team1?:  {
        __typename: "MatchTeam",
        team_id?: string | null,
        status?: string | null,
        players?:  Array< {
          __typename: "MatchPlayer",
          player_id?: string | null,
          name: string,
        } | null > | null,
        scores?: Array< string | null > | null,
        winner?: boolean | null,
        seed?: string | null,
      } | null,
      team2?:  {
        __typename: "MatchTeam",
        team_id?: string | null,
        status?: string | null,
        players?:  Array< {
          __typename: "MatchPlayer",
          player_id?: string | null,
          name: string,
        } | null > | null,
        scores?: Array< string | null > | null,
        winner?: boolean | null,
        seed?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      competition?:  {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListOrganisationsQueryVariables = {
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganisationsQuery = {
  listOrganisations?:  {
    __typename: "ModelOrganisationConnection",
    items?:  Array< {
      __typename: "Organisation",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      tournaments?:  {
        __typename: "ModelTournamentConnection",
        items?:  Array< {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetOrganisationQueryVariables = {
  id?: string,
};

export type GetOrganisationQuery = {
  getOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    tournaments?:  {
      __typename: "ModelTournamentConnection",
      items?:  Array< {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetTournamentQueryVariables = {
  id?: string,
};

export type GetTournamentQuery = {
  getTournament?:  {
    __typename: "Tournament",
    id: string,
    name: string,
    start_date?: string | null,
    end_date?: string | null,
    location?: string | null,
    location_country_code?: string | null,
    logo?: string | null,
    theme?: string | null,
    match_count?: number | null,
    player_count?: number | null,
    organisation_id: string,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      tournaments?:  {
        __typename: "ModelTournamentConnection",
        items?:  Array< {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    competitions?:  {
      __typename: "ModelCompetitionConnection",
      items?:  Array< {
        __typename: "Competition",
        id: string,
        slug: string,
        name: string,
        max_round_id?: number | null,
        players_per_team?: number | null,
        tournament_id: string,
        createdAt: string,
        updatedAt: string,
        tournament?:  {
          __typename: "Tournament",
          id: string,
          name: string,
          start_date?: string | null,
          end_date?: string | null,
          location?: string | null,
          location_country_code?: string | null,
          logo?: string | null,
          theme?: string | null,
          match_count?: number | null,
          player_count?: number | null,
          organisation_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        matches?:  {
          __typename: "ModelMatchConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetCompetitionQueryVariables = {
  id?: string,
};

export type GetCompetitionQuery = {
  getCompetition?:  {
    __typename: "Competition",
    id: string,
    slug: string,
    name: string,
    max_round_id?: number | null,
    players_per_team?: number | null,
    tournament_id: string,
    createdAt: string,
    updatedAt: string,
    tournament?:  {
      __typename: "Tournament",
      id: string,
      name: string,
      start_date?: string | null,
      end_date?: string | null,
      location?: string | null,
      location_country_code?: string | null,
      logo?: string | null,
      theme?: string | null,
      match_count?: number | null,
      player_count?: number | null,
      organisation_id: string,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        tournaments?:  {
          __typename: "ModelTournamentConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitions?:  {
        __typename: "ModelCompetitionConnection",
        items?:  Array< {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    matches?:  {
      __typename: "ModelMatchConnection",
      items?:  Array< {
        __typename: "Match",
        id: string,
        tournament_id: string,
        competition_id: string,
        round_id: number,
        match_number: number,
        team1?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        team2?:  {
          __typename: "MatchTeam",
          team_id?: string | null,
          status?: string | null,
          scores?: Array< string | null > | null,
          winner?: boolean | null,
          seed?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        competition?:  {
          __typename: "Competition",
          id: string,
          slug: string,
          name: string,
          max_round_id?: number | null,
          players_per_team?: number | null,
          tournament_id: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetMatchQueryVariables = {
  id?: string,
};

export type GetMatchQuery = {
  getMatch?:  {
    __typename: "Match",
    id: string,
    tournament_id: string,
    competition_id: string,
    round_id: number,
    match_number: number,
    team1?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    team2?:  {
      __typename: "MatchTeam",
      team_id?: string | null,
      status?: string | null,
      players?:  Array< {
        __typename: "MatchPlayer",
        player_id?: string | null,
        name: string,
        affiliations?:  {
          __typename: "Affiliations",
          country?: string | null,
          sponsor?: string | null,
        } | null,
      } | null > | null,
      scores?: Array< string | null > | null,
      winner?: boolean | null,
      seed?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    competition?:  {
      __typename: "Competition",
      id: string,
      slug: string,
      name: string,
      max_round_id?: number | null,
      players_per_team?: number | null,
      tournament_id: string,
      createdAt: string,
      updatedAt: string,
      tournament?:  {
        __typename: "Tournament",
        id: string,
        name: string,
        start_date?: string | null,
        end_date?: string | null,
        location?: string | null,
        location_country_code?: string | null,
        logo?: string | null,
        theme?: string | null,
        match_count?: number | null,
        player_count?: number | null,
        organisation_id: string,
        createdAt: string,
        updatedAt: string,
        organisation?:  {
          __typename: "Organisation",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitions?:  {
          __typename: "ModelCompetitionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      matches?:  {
        __typename: "ModelMatchConnection",
        items?:  Array< {
          __typename: "Match",
          id: string,
          tournament_id: string,
          competition_id: string,
          round_id: number,
          match_number: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
