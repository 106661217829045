import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { gql } from '@apollo/client'
import { Helmet } from 'react-helmet'

import { APP } from '../lib/constants'
import { Footer } from '../components/Footer'
import { getTournamentsByOrganisation } from '../graphql/queries'
import { GetTournamentsByOrganisationQuery, GetTournamentsByOrganisationQueryVariables, ModelSortDirection, Tournament } from '../API'
import { Header } from '../components/Header'
import { LoadingMessage } from '../components/LoadingMessage'
import { TournamentList } from '../components/TournamentList'
import usePollingQuery from '../hooks/usePollingQuery'

export default function Tournaments() {
	const q = usePollingQuery<GetTournamentsByOrganisationQuery, GetTournamentsByOrganisationQueryVariables>(gql(getTournamentsByOrganisation), {
		variables: {
			organisation_id: 'bwf',
			sortDirection: ModelSortDirection.DESC,
		},
		pollInterval: 60000
	})

	const tournaments = q.data?.getTournamentsByOrganisation?.items as Tournament[]

	// q.loading
	// q.fetchMore
	// q.error

	return (
		<>
			<Helmet>
				<title>Tournaments | {APP.htmlName}</title>
			</Helmet>

			<Header />

			<Container component='main' maxWidth='lg'>
				<Typography variant="h2">Tournaments</Typography>
			</Container>

			<Container component='main' maxWidth='lg'>
				<Card>
					<CardContent>
						{q.loading ?
							<LoadingMessage message='Loading Tournaments...' /> :
							tournaments.length > 0 && <TournamentList tournaments={tournaments} />
						}
					</CardContent>
				</Card>
			</Container>

			<Footer />
		</>
	)
}
