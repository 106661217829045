import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Match } from '../API'
import { shortRoundName } from '../lib/Utils'
import { TeamRow } from './TeamRow'

import './MatchTable.css'

interface MatchTableProps {
	matches: (Match | null)[]
	showCompetition?: Boolean
}

export const MatchTable: FunctionComponent<MatchTableProps> = ({ matches, showCompetition = false }) => {
	return (
		<TableContainer>
			<Table size="small">
				<TableBody>
					{matches.map(match => match && <MatchRow key={match.id} match={match} showCompetition={showCompetition} />)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

interface MatchRowProps {
	match: Match
	showCompetition?: Boolean
}

export const MatchRow: FunctionComponent<MatchRowProps> = ({ match, showCompetition = false }) => {
	return (
		<TableRow hover component={Link} to={'/t/' + match.tournament_id + '/' + match.competition?.slug + '/' + match.match_number}>
			{showCompetition && <TableCell>{match.competition?.slug?.toUpperCase()}</TableCell>}
			<TableCell>{match.round_id && shortRoundName(match.round_id)}</TableCell>
			<TableCell className={match.team1?.winner ? 'winner' : ''}><TeamRow team={match.team1} opposingTeam={match.team2} /></TableCell>
			<TableCell>{match.team1?.scores?.map((score, gameNumber) => <div key={gameNumber}>{score}</div>)}</TableCell>
			<TableCell>{match.team2?.scores?.map((score, gameNumber) => <div key={gameNumber}>{score}</div>)}</TableCell>
			<TableCell className={match.team2?.winner ? 'winner' : ''}><TeamRow team={match.team2} opposingTeam={match.team1} /></TableCell>
		</TableRow>
	)
}
