import React from 'react'
import { gql } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { GetMatchesByTournamentQuery, GetMatchesByTournamentQueryVariables, Match, ModelSortDirection, Tournament } from '../API'
import usePollingQuery from '../hooks/usePollingQuery'
import { MatchTable } from '../components/MatchTable'
import { APP } from '../lib/constants'
import { TournamentBreadcrumbs } from '../components/TournamentBreadcrumbs'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { LoadingMessage } from '../components/LoadingMessage'

export const getMatchesByTournament = /* GraphQL */ `
  query GetMatchesByTournament(
    $tournament_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMatchesByTournament(
      tournament_id: $tournament_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournament_id
        competition_id
        round_id
        match_number
        team1 {
          team_id
          players {
            player_id
            name
			affiliations {
				country
				sponsor
			}
          }
          scores
          winner
          seed
        }
        team2 {
          team_id
          players {
            player_id
            name
			affiliations {
				country
				sponsor
			}
          }
          scores
          winner
          seed
        }
        competition {
          id
          slug
          name
        }
      }
      nextToken
    }
  }
`;

export default function TournamentMatches({ tournament }: { tournament: Tournament }) {
	// TODO: Only poll if the tournament is currently running?
	// TODO: Only show per competition or per round?
	// TODO: Add pagination
	const matchesQ = usePollingQuery<GetMatchesByTournamentQuery, GetMatchesByTournamentQueryVariables>(gql(getMatchesByTournament), {
		variables: {
			tournament_id: tournament.id,
			sortDirection: ModelSortDirection.DESC,
			limit: 50,
		},
		pollInterval: 60000
	})
	const matches = matchesQ.data?.getMatchesByTournament?.items as Match[]

	// console.log('nextToken', matchesQ.data?.getMatchesByTournament?.nextToken)
	// matchesQ.fetchMore()

	return (
		<>
			<Helmet>
				<title>Matches | {tournament.name} | {APP.htmlName}</title>
			</Helmet>

			<Container component='header' maxWidth='lg'>
				<TournamentBreadcrumbs tournament={tournament} />
				<Typography variant='h2'>Matches</Typography>
			</Container>

			<Container component='main' maxWidth='lg'>
				<Card>
					<CardContent>
						{matchesQ.loading && <LoadingMessage message='Loading Matches...' />}
						{matches && matches.length > 0 && <MatchTable matches={matches} showCompetition={true} />}
					</CardContent>
				</Card>
			</Container>
		</>
	)
}
