/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ifeGV4TWL",
    "aws_user_pools_web_client_id": "1t033qdbrojpgap3bunt9t99t8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ybuh75hbl5cm3ptjjk65h2a3nm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qag4ogyvgreqvcdi66ghle547u"
};


export default awsmobile;
