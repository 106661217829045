import React from 'react'
import { gql } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { Competition, GetMatchesByCompetitionQueryVariables, Match, ModelMatchConnection, ModelSortDirection, Tournament } from '../API'
import usePollingQuery from '../hooks/usePollingQuery'
import { MatchTable } from '../components/MatchTable'
import { KnockoutBracket } from '../components/KnockoutBracket'
import { APP } from '../lib/constants'
import { TournamentBreadcrumbs } from '../components/TournamentBreadcrumbs'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { LoadingMessage } from '../components/LoadingMessage'

export const getMatchesByCompetition = /* GraphQL */ `
  query GetMatchesByCompetition(
    $competition_id: ID
    $match_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMatchesByCompetition(
      competition_id: $competition_id
      match_number: $match_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournament_id
        competition_id
        round_id
        match_number
        team1 {
          team_id
          players {
            player_id
            name
            affiliations {
			  country
			  sponsor
			}
          }
		  status
          scores
          winner
          seed
        }
        team2 {
          team_id
          players {
            player_id
            name
            affiliations {
			  country
			  sponsor
			}
          }
		  status
          scores
          winner
          seed
        }
      }
      nextToken
    }
  }
`;

export default function CompetitionProfile({ tournament, competition }: { tournament: Tournament, competition: Competition }) {
	// TODO: Only poll if the competition is currently running?
	const match_q = usePollingQuery<{ getMatchesByCompetition?: ModelMatchConnection }, GetMatchesByCompetitionQueryVariables>(gql(getMatchesByCompetition), {
		variables: {
			competition_id: competition.id,
			sortDirection: ModelSortDirection.DESC
		},
		pollInterval: 60000
	})

	const matches: Match[] | undefined = match_q.data?.getMatchesByCompetition?.items?.filter((match): match is Match => match !== undefined && match !== null).map(match => {
		if (!match && !competition) return match
		return { ...match, competition }
	})

	const matchesByPosition: { [key: number]: Match } | undefined = matches?.reduce((lookup, match) => {
		lookup[match.match_number!] = match
		return lookup
	}, {} as { [key: number]: Match })

	return (
		<>
			<Helmet>
				<title>{competition.name} | {tournament.name} | {APP.htmlName}</title>
			</Helmet>

			<Container component='header' maxWidth='lg'>
				<TournamentBreadcrumbs tournament={tournament} />
				<Typography variant='h2'>{competition.name}</Typography>
			</Container>

			<Container component='main' maxWidth='lg'>

				{match_q.loading && <LoadingMessage message='Loading Competition...' />}

				{competition && matches && matches.length > 0 && matchesByPosition && (
					<Card className='Spacer15'>
						<CardContent>
							<KnockoutBracket numRounds={competition.max_round_id || 5} matches={matchesByPosition} competition={competition} />
						</CardContent>
					</Card>
				)}

				{matches && matches.length > 0 && (
					<Card>
						<CardContent>
							<MatchTable matches={matches} />
						</CardContent>
					</Card>
				)}

			</Container>
		</>
	)
}
