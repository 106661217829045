import React from "react"
import { MatchPlayer } from "../API"
import { CountryFlag } from "./CountryFlag"

export const PlayerRow = ({ player }: { player: MatchPlayer }) => {
	return (
		<div>
			{player.affiliations?.country && <CountryFlag code={player.affiliations.country} />}
			{player.name}
		</div>
	)
}
