import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Competition, Match, Tournament } from '../API'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { longRoundName } from '../lib/Utils'
import { Breadcrumbs, Link } from '@material-ui/core'

import './TournamentBreadcrumbs.css'
import { CountryFlag } from './CountryFlag'

export const TournamentBreadcrumbs = ({ tournament, competition, match }: { tournament?: Tournament, competition?: Competition, match?: Match }) => {
	return (
		<Breadcrumbs separator={<ArrowRightIcon />} aria-label="breadcrumb">
			{tournament && (
				<Link component={RouterLink} to={'/t/' + tournament.id}>
					{tournament.location_country_code && <CountryFlag code={tournament.location_country_code} />}
					{tournament.name}
				</Link>)}
			{competition && <Link component={RouterLink} to={'/t/' + competition.tournament_id + '/' + competition.slug}>{competition.name}</Link>}
			{match?.round_id && <span>{longRoundName(match.round_id)}</span>}
		</Breadcrumbs>
	)
}
