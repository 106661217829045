import React from 'react'
import { Competition, Match } from '../API'
import { getMatchPositionsForRound, getNumberOfMatchesInRound } from 'badminton3-lib'
import { TeamRow } from './TeamRow'

import './KnockoutBracket.css'
import { Link } from 'react-router-dom'

export const KnockoutBracket = ({ competition, matches, numRounds }: { competition: Competition, matches: { [key: number]: Match }, numRounds: number }) => {
	const maxRows = getNumberOfMatchesInRound(numRounds)
	const roundNumbers = Array.from(Array(numRounds).keys()).map(roundNumber => roundNumber + 1).reverse()
	const sizeClass = true ? 'KnockoutBracketDensePosition ' : ''

	return (
		<div className="KnockoutBracket" style={{
			gridTemplateColumns: 'repeat(' + numRounds + ', auto)',
			gridTemplateRows: 'repeat(' + maxRows + ', auto)',
			gridAutoFlow: 'column'
		}}>
			{roundNumbers.map(roundId => {
				const matchPositionsForRound = getMatchPositionsForRound(roundId)
				const rowSpan = maxRows / matchPositionsForRound.length
				return matchPositionsForRound.map(matchPosition => {
					const match = matches[matchPosition]
					return (
						<div key={matchPosition} className={sizeClass + 'KnockoutBracketPosition KnockoutBracketRound' + (numRounds - roundId + 1) + ' KnockoutBracketRoundId' + roundId + ' KnockoutMatch' + matchPosition} style={{ gridRowEnd: 'span ' + rowSpan }}>
							<div className="KnockoutBracketSpacer"></div>
							<div className={'KnockoutBracketTeamPosition KnockoutBracketTopTeam'}>
								<Link className="KnockoutBracketTeam" to={'/t/' + competition.tournament_id + '/' + competition.slug + '/' + matchPosition}>
									<TeamRow team={match?.team1} opposingTeam={match?.team2} numPlayersPerTeam={competition.players_per_team} />
								</Link>
							</div>
							<div className={'KnockoutBracketTeamPosition KnockoutBracketBottomTeam'}>
								<Link className="KnockoutBracketTeam" to={'/t/' + competition.tournament_id + '/' + competition.slug + '/' + matchPosition}>
									<TeamRow team={match?.team2} opposingTeam={match?.team1} numPlayersPerTeam={competition.players_per_team} />
								</Link>
							</div>
							<div className="KnockoutBracketSpacer"></div>
						</div>
					)
				})
			})}
		</div>
	)
}
