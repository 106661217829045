import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Tournament } from '../API'
import { APP } from '../lib/constants'
import CompetitionProfile from '../screens/CompetitionProfile'
import MatchProfile from '../screens/MatchProfile'

export default function CompetitionRouter({ tournament }: { tournament: Tournament}) {
	const { competition_id } = useParams<{ competition_id: string }>()

	const matchingCompetitions = tournament.competitions?.items?.filter(competition => competition?.slug == competition_id)
	if (matchingCompetitions?.length != 1) {
		// TODO: Return 404 and display list of available competitions
		return <p>Unknown Competition!</p>
	}
	const competition = matchingCompetitions[0]!

	return (
		<>
			<Helmet>
				<title>{competition.name} | {tournament.name} | {APP.htmlName}</title>
			</Helmet>

			<Switch>
				<Route exact path='/t/:tournament_id/:competition_id/:match_number'><MatchProfile tournament={tournament} competition={competition} /></Route>
				<Route exact path='/t/:tournament_id/:competition_id'><CompetitionProfile tournament={tournament} competition={competition} /></Route>
				{/* TODO: 404 page - report to logging? */}
				<Route path='/'><p>404</p></Route>
			</Switch>
		</>
	)

}
