import React from 'react'
import { Card, CardActionArea, Container, Grid, Link, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { Competition, Tournament } from '../API'
import { CountryFlag } from '../components/CountryFlag'
import { sortCompetitions } from '../lib/Utils'
import { getTournamentDateRange } from '../lib/datetime'
import DateRangeIcon from '@material-ui/icons/DateRange'

import '../components/CardButton.css'

export default function TournamentProfile({ tournament }: { tournament: Tournament }) {
	return (
		<>
			<Container component='header' maxWidth='lg'>
				<Typography variant='h2'>
					<Link component={RouterLink} to={'/t/' + tournament.id} color='textPrimary'>
						{tournament.logo ?
							<img src={tournament.logo} alt={tournament.name} title={tournament.name} style={{ maxHeight: 80 }} /> :
							tournament.name
						}
					</Link>
				</Typography>

				<Grid container alignItems='center'>
					<Grid item style={{ marginRight: '0.5em' }}><DateRangeIcon fontSize='small' /></Grid>
					<Grid item>{getTournamentDateRange(tournament.start_date, tournament.end_date)}</Grid>
				</Grid>

				{tournament.location && (
					<div>
						{tournament.location_country_code && <CountryFlag code={tournament.location_country_code} />}
						{tournament.location}
					</div>
				)}
			</Container>

			<Container component='main' maxWidth='lg'>
				<Grid container spacing={2} justify='center' className='Spacer15'>
					{tournament.competitions?.items && (tournament.competitions.items.slice() as Competition[]).sort(sortCompetitions).map(competition => {
						if (!competition) return
						return (
							<Grid item sm key={competition.id}>
								<CardActionArea className='CardButtonActionArea' component={RouterLink} to={'/t/' + competition.tournament_id + '/' + competition.slug}>
									<Card className='CardButton'>
											<div className='CardButtonPrimaryText'>{competition.slug!.toUpperCase()}</div>
											<div className='CardButtonSecondaryText'>{competition.name}</div>
									</Card>
								</CardActionArea>
							</Grid>
						)
					})}
				</Grid>

				<Grid container spacing={2} justify='center'>
					<Grid item sm={4}>
						<CardActionArea className='CardButtonActionArea' component={RouterLink} to={'/t/' + tournament.id + '/matches'}>
							<Card className='CardButton'>
								<div className='CardButtonPrimaryText'>{tournament.match_count || 'Match List'}</div>
								{tournament.match_count && <div className='CardButtonSecondaryText'>Matches</div>}
							</Card>
						</CardActionArea>
					</Grid>

					{/* <Grid item sm>
						<CardActionArea className='CardButtonActionArea' component={RouterLink} to={'/t/' + tournament.id + '/players'}>
							<Card className='CardButton'>
								<div className='CardButtonPrimaryText'>{tournament.player_count || 'Player List'}</div>
								{tournament.player_count && <div className='CardButtonSecondaryText'>Players</div>}
							</Card>
						</CardActionArea>
					</Grid> */}

					{/* <Grid item sm>
						<CardActionArea className='CardButtonActionArea' component={RouterLink} to={'/t/' + tournament.id + '/media'}>
							<Card className='CardButton'>
								<div className='CardButtonPrimaryText'>123</div>
								<div className='CardButtonSecondaryText'>Videos</div>
							</Card>
						</CardActionArea>
					</Grid> */}
				</Grid>

			</Container>
		</>
	)
}

/*
Official Tournament Website
Tournament Series Info
*/
