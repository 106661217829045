import { format, parseISO } from "date-fns"

export interface DateTimeFormatOptions {
	day: string
	month: string
	year: string
	separator: string
	rangeSeparator: string
}

export const getTournamentDateRange = (start: string | undefined | null, end: string | undefined | null, stringFormat: DateTimeFormatOptions = {
	day: 'd',
	month: 'MMMM',
	year: 'y',
	separator: ' ',
	rangeSeparator: ' - ',
}) => {
	if (!start) return undefined
	const startDate = parseISO(start)

	const standardFormat = [
		stringFormat.day,
		stringFormat.month,
		stringFormat.year
	]

	if (!end) return format(startDate, standardFormat.join(stringFormat.separator))
	const endDate = parseISO(end)

	const sameYear = startDate.getUTCFullYear() == endDate.getUTCFullYear()
	const sameMonth = sameYear && startDate.getUTCMonth() == endDate.getUTCMonth()
	const sameDay = sameMonth && startDate.getUTCDate() == endDate.getUTCDate()

	const startDateFormat = []
	if (!sameDay) startDateFormat.push(stringFormat.day)
	if (!sameMonth) startDateFormat.push(stringFormat.month)
	if (!sameYear) startDateFormat.push(stringFormat.year)

	const dateString = (
		startDateFormat.length > 0 ?
			format(startDate, startDateFormat.join(stringFormat.separator))
			+ stringFormat.rangeSeparator
		: ''
	) + format(endDate, standardFormat.join(stringFormat.separator))

	return dateString
}
