import { Container, Link } from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { COMPANY } from '../lib/constants'

import './Footer.css'

export const Footer = () => {
	return (
		<Container component='footer' maxWidth='lg'>
			<div><Link component={RouterLink} to='/about'><strong>{COMPANY.name}</strong> {COMPANY.type}</Link></div>
		</Container>
	)
}

{/* <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">Learn React</a> */}
