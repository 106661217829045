import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core'

import { Tournament } from '../API'
import { sortCompetitions } from '../lib/Utils'
import { CountryFlag } from '../components/CountryFlag'
import { getTournamentDateRange } from '../lib/datetime'

import './TournamentList.css'

export const TournamentList = ({ tournaments }: { tournaments: Tournament[] }) => {
	return (
		<List className='TournamentList'>
			{tournaments?.map(tournament => {
				if (!tournament) return
				return (
					<ListItem button component={Link} to={'/t/' + tournament.id} key={tournament.id}>
						{/* {tournament.organisation_id == 'bwf' && (
							<ListItemAvatar>
								<Avatar alt="BWF" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/2012_BWF_logo.svg/1280px-2012_BWF_logo.svg.png" className='AvatarImageContain' />
							</ListItemAvatar>
						)} */}
						<ListItemAvatar>
							<Avatar className='AvatarWithNoBackground'>{tournament.location_country_code && <CountryFlag code={tournament.location_country_code} />}</Avatar>
						</ListItemAvatar>

						<ListItemText primary={tournament.name} secondary={getTournamentDateRange(tournament.start_date, tournament.end_date)}></ListItemText>

						<ListItemSecondaryAction>
							{tournament.competitions?.items?.slice().sort(sortCompetitions).map(competition => {
								if (!competition) return
								return <Button key={competition.id} component={Link} to={'/t/' + tournament.id + '/' + competition.slug} title={competition.name}>{competition.slug}</Button>
							})}
						</ListItemSecondaryAction>
					</ListItem>
				)
			})}
		</List>
	)
}
