import { Container } from '@material-ui/core'
import React from 'react'
import AppBar from './AppBar'

import './Header.css'

export const Header = () => {
	return (
		<div className='HeaderBackground'>
			<Container maxWidth='lg'>
				<AppBar />
			</Container>
		</div>
	)
}
