import React from 'react'

import { getCountryByCode } from "../lib/countries"
import Twemoji from 'react-twemoji'

import './CountryFlag.css'

export const CountryFlag = ({ code }: { code: string }) => {
	const country = getCountryByCode(code)

	if (!country || !country.emoji) {
		if (code == 'TPE') {
			return (
				<span>
					<img
						className="CountryFlag"
						title={country?.name}
						src="https://upload.wikimedia.org/wikipedia/commons/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg"
						alt="Chinese Taipei flag for Olympic Games"
						draggable="false"
					/>
				</span>
			)
		} else {
			return <span className="CountryFlagText" title={country?.name}>{code}</span>
		}
	}

	return (
		<Twemoji
			tag="span"
			options={{
				className: 'CountryFlag',
				folder: 'svg',
				ext: '.svg',
				attributes: () => ({ title: country.name }) }}
		>
			{country.emoji}
		</Twemoji>
	)
}
